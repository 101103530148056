import { SatisfacaoClientePergunta } from "./SatisfacaoClientePergunta";
import { SatisfacaoClienteResposta } from "./SatisfacaoClienteResposta";

export class SatisfacaoCliente {
    id!: number; // Long in Java is typically represented as number in TypeScript
    nomePesquisa!: string;
    usuarioCadastro!: string;
    dataCadastro!: string;
    dataAlteracao!: string;
    versao!: number; // Integer in Java is typically represented as number in TypeScript
    status!: string;
    textoIntroducao!: string;
    perguntas!: SatisfacaoClientePergunta[];
    respostas!: SatisfacaoClienteResposta[];
}
